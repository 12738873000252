html,
body {
  overscroll-behavior-x: none;
}

// ::-webkit-scrollbar {
//   width: 0;
//   height: 0;
// }

// html {
//   scrollbar-width: none;
// }

// html {
//   -ms-overflow-style: none;
// }
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #111218;
}

::-webkit-scrollbar-thumb {
  background-color: var(--border);
  border-radius: 10px;
  border: 2px solid #111218;
}

::-webkit-scrollbar-thumb:hover {
  background: #1f2433;
}

::-webkit-scrollbar-corner {
  background: #111218;
}


body {
  margin: 0;
  background-size: 3em 3em;
  background-color: var(--background);
  opacity: 1;
  // overflow: -moz-scrollbars-none;
  // overflow: -ms-scrollbars-none;

  &.modal-open {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  font-family: var(--ss-font-primary);
  -webkit-tap-highlight-color: transparent;
}

:fullscreen {
  background: black;
}

a {
  cursor: pointer;
}

.cap-it-bro {
  text-transform: capitalize;
}

.ss-card-grid-header {
  font-size: 24px;
  color: var(--ss-text-primary);
  margin-bottom: 1em;
}

.ss-card-wrapper {
  // background: black;
  margin: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
}

@keyframes flash {
  0% {
    clip-path: polygon(0 50%, 100% 50%, 100% 0, 0 0);
  }

  100% {
    clip-path: none;
  }
}

.ss-board-view-utility-row {
  position: absolute;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;

  .board-tools {
    display: flex;
    justify-content: flex-end;
  }

  .fs-toggle {
    height: 25px;
    width: 224px;
    background: #151515;
    font-size: 18px;
    font-family: 'Share';
    text-align: center;
    padding: 10px 0px 6px 0px;
    border-bottom-left-radius: 16px;
    color: #dfe0e2;
    font-weight: 500;
    cursor: pointer;
  }

  .action-toggle {
    background: #1b1b1b;
    height: 40px;
    width: 40px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &:last-child {
      margin-right: 32px;
    }

    cursor: pointer;
    cursor: pointer;

    .sound-on {
      svg {
        width: 18px;
        transform: translateY(2px);

        path {
          fill: var(--ss-brand-primary);
        }
      }
    }

    svg {
      width: 18px;
      transform: translateY(2px);

      path {
        fill: #dfe0e2;
      }
    }
  }
}

// .hot-toast-message {
//   font-family: 'Source Code Pro', monospace;
//   font-weight: 100;
//   font-size: 14px;
// }

.tool-btn {
  .toggle-switch {
    height: 16px !important;
    width: 34px !important;

    &.on {
      background-color: #29947d !important;
    }

    &.on .toggle-switch-thumb {
      transform: translateX(17px) !important;
    }
  }
  .toggle-switch-thumb {
    width: 12px !important;
    height: 12px !important;
  }
}

.tool-item {
  .toggle-switch {
    width: 30px !important;
    height: 18px !important;

    .toggle-switch-thumb {
      width: 16px !important;
      height: 16px !important;
      top: 1px !important;
      left: 1px !important;
    }

    &.on .toggle-switch-thumb {
      transform: translateX(12px) !important;
    }
  }
}

// PAGE STRUCTURE

.ss-page-wrapper {
  width: 100%;

  &.auth {
    display: flex;
    justify-content: center;
    padding-top: 8%;

    @media screen and (max-width: 768px) {
      padding-top: 32px;
    }
  }
}

.ss-base-header {
  color: var(--ss-text-primary);
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
}

// NEW BASE STYLES FOR PAGES WITH TITLE AND GRID OR LIST
.ss-base-page-wrapper {
  width: 100%;
  padding: 1em;

  @media screen and (max-width: 768px) {
    padding: 1em;
  }

  &.narrow {
    max-width: 36em;
    margin: 0 auto;
    padding-top: 3em;
  }

  .content {
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 1em;
  }

  .base-page-title-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    .left {
      display: block;

      .chip {
        background: var(--ss-colors-yellow-200);
        padding: 0.5em 0.75em;
        border-radius: 4px;
        width: fit-content;
        font-size: 0.75rem;
        font-weight: 600;
        margin-bottom: 1em;
      }

      .title {
        font-size: 1.75rem;
        font-weight: 600;
        font-family: var(--ss-font-secondary);
        color: var(--ss-text-primary);
      }
    }
  }
}

.ss-auth-content {
  max-width: 300px;
  min-width: 300px;

  .ss-auth-header {
    color: var(--ss-text-primary);
    font-size: 26px;
    margin-bottom: 16px;
    font-weight: 700;
    font-family: var(--ss-font-secondary);
    font-style: italic;

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .auth-sub-header {
    color: var(--ss-text-secondary);
    font-family: var(--ss-font-secondary);
    line-height: 1.7;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .ss-auth-form {
    width: 100%;

    .ss-auth-form-sub-row {
      text-align: center;
      margin-top: 32px;

      .auth-link {
        font-family: var(--ss-font-roboto);
        color: #646e7e; // needs var
        font-size: 12px;
        font-weight: 300;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

//OVERLAYS

.ss-blur-overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgb(117 119 147 / 70%);
  backdrop-filter: blur(5px);
  overflow: scroll;
}

.ss-modal-header {
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  .header-name {
    font-size: 1.25rem;
    font-weight: 800;
    color: var(--ss-text-soft-mute);
    margin-bottom: 0.5rem;
  }

  .header-desc {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--ss-text-medium-mute);
    margin-bottom: 1em;
  }
}

.ss-upgrade-modal-wrapper {
  max-width: 26em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.6;
  padding: 32px;

  .logo svg path {
    fill: #e9a300; // Needs Var
  }

  .title {
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    color: var(--ss-text-primary);
    font-family: var(--ss-font-secondary);
    font-style: italic;
  }

  .sub-title {
    color: var(--ss-text-secondary);
    font-size: 0.938rem;
  }
}

.ss-form-modal-wrapper {
  max-width: 26em;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.6;
  padding: 32px;

  .title {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 0.25em;
    color: var(--ss-text-primary);
  }

  .sub-title {
    color: var(--ss-text-medium-mute);
    font-size: 0.938rem;
  }
}

.ss-info-modal-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  line-height: 1.6;
  padding-bottom: 32px;

  .modal-header {
    font-size: 1.25rem;
    font-weight: 800;
    color: var(--ss-text-primary);
    border-bottom: 1px solid #323541; // needs var
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 1em;
  }

  .warning-row {
    display: flex;
    gap: 0.5em;
    margin-bottom: 1.5em;

    .icon {
      display: block;
    }

    .prompt {
      font-size: 0.875rem;
      color: var(--ss-text-soft-mute);
    }
  }

  .mock-row {
    background: #ced4ea;
    display: flex;
    justify-content: space-between;
    min-height: 56px;
    margin-bottom: 1.75em;
    width: 100%;
    border-radius: 0.5em;
    overflow: hidden;

    .mock-cell {
      cursor: pointer;
      border-right: 1px solid #0b0d15;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #222222;
      font-weight: 600;
      width: 100%;
      font-size: 0.875rem;

      &:last-child {
        border-right: none;
      }

      &.active {
        background: #0ca4dd;
      }
    }
  }

  .info-item {
    margin-bottom: 1em;

    .title {
      color: var(--ss-text-primary);
      font-size: 1.5rem;
      font-weight: 300;
    }

    .sub-title {
      color: var(--ss-text-soft-mute);
      font-weight: 300;
      margin-bottom: 0.25em;
    }

    .values {
      color: var(--ss-text-muted);
      margin-bottom: 1em;
    }

    .desc {
      color: var(--ss-text-soft-mute);
      font-size: 0.875rem;
      margin-bottom: 1em;
      line-height: 26px;
      max-width: 90%;
    }
  }
}

//CARDS AND MODALS

.ss-base-card {
  border-radius: 0.5em;
  width: 100%;
  min-height: 2em;
  padding: 1em;
  background: var(--ss-base-card-bg);
  // border: 0.8px solid var(--ss-card-border);
  box-shadow: 2px 4px 4px var(--ss-card-shadow);
  color: var(--ss-text-primary);
}

.ss-base-page-card {
  border-radius: 1em;
  overflow: hidden;
  width: 100%;
  min-height: 2em;
  background: var(--ss-card-bg);
  border: 0.8px solid var(--ss-card-border);
  box-shadow: 2px 4px 4px var(--ss-card-shadow);

  .page-card-header {
    background: var(--ss-card-header-bg);
    padding: 1.25em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      font-size: 0.938rem;
      color: var(--ss-colors-off-white);
      font-weight: 600;
    }

    .search-input {
      border-radius: 0.5em;
      outline: none;
      background: #151416; // NEEDS VAR
      min-height: 32px;
      min-width: 14em;
      border: none;
      color: var(--ss-colors-off-white);
      padding: 0px 12px;

      &::placeholder {
        color: #717f9d; // NEEDS VAR
      }
    }
  }

  .page-card-body {
    padding: 1em;
  }
}

.ss-card-divider {
  border-top: 0.8px solid var(--ss-card-border);
}

.ss-sport-card-name {
  font-weight: 500;
  margin-left: 1em;
  font-family: var(--ss-font-secondary);
}

.ss-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding: 156px 32px;
  background: rgb(17 25 41 / 88%);

  @media screen and (max-width: 768px) {
    padding: 96px 0px;
  }

  .modal-container {
    background-color: var(--ss-colors-off-white);
    border-radius: 48px;
    min-width: 384px;
    max-width: 384px;
    height: fit-content;
    padding: 48px 32px;

    .message {
      font-family: var(--ss-font-roboto);
      line-height: 1.7;
      font-size: 16px;
      font-weight: 300;
    }

    .link {
      font-family: var(--ss-font-roboto);
      line-height: 1.7;
      font-size: 15px;
      font-weight: 500;
      color: var(--ss-brand-primary);
    }
  }

  &.danger {
    background: #1e0000; // NEEDS VAR

    .destructive-confirm {
      padding: 32px;
      max-width: 480px;

      .warning-header {
        font-family: var(--ss-font-roboto);
        line-height: 1.7;
        font-size: 26px;
        font-weight: 600;
        color: var(--ss-colors-off-white);
        margin-bottom: 16px;
      }

      .warning-text {
        font-family: var(--ss-font-roboto);
        line-height: 1.7;
        font-size: 24px;
        font-weight: 500;
        color: var(--ss-colors-red-lightest);
      }

      .cancel-text {
        text-align: center;
        margin-top: 32px;
        font-family: var(--ss-font-roboto);
        line-height: 1.7;
        font-size: 16px;
        font-weight: 500;
        color: var(--ss-colors-off-white);
        cursor: pointer;
      }
    }
  }

  &.form {
    background: var(--ss-colors-darks-rock);

    .form-wrapper {
      padding: 32px;
      max-width: 480px;

      .form-header {
        font-family: var(--ss-font-roboto);
        line-height: 1.7;
        font-size: 24px;
        font-weight: 600;
        color: var(--ss-colors-off-white);
      }

      .form-text {
        font-family: var(--ss-font-roboto);
        line-height: 1.7;
        font-size: 14px;
        font-weight: 300;
        color: var(--ss-colors-off-white);
        margin-bottom: 16px;
      }

      .cancel-text {
        text-align: center;
        margin-top: 32px;
        font-family: var(--ss-font-roboto);
        line-height: 1.7;
        font-size: 16px;
        font-weight: 500;
        color: var(--ss-colors-off-white);
        cursor: pointer;
      }

      .ss-input {
        width: 100%;
        min-height: 156px;
      }
    }
  }

  &.solid {
    background: var(--ss-colors-darks-slate);
    animation: slideUp 800ms cubic-bezier(0.75, -0.02, 0.07, 1.26) 0ms 1;

    &.animate-out {
      animation: slideDown 800ms cubic-bezier(0.75, -0.02, 0.07, 1.26) 0ms 1;
    }
  }
}

@keyframes slideUp {
  0% {
    top: 100%;
  }

  100% {
    top: 0;
  }
}

@keyframes slideDown {
  0% {
    top: 0;
  }

  100% {
    top: 100%;
  }
}

.ss-modal-card {
  border-radius: 24px;
  min-width: 384px;
  min-height: 400px;
  padding: 32px;
  background: #13141b; // needs var
  // border: 0.8px solid var(--ss-card-border);
  box-shadow: 2px 4px 2px var(--ss-card-shadow);

  @media screen and (max-width: 768px) {
    min-width: 0px;
    max-width: 100%;
    width: 100%;
    margin: 0px 16px;
  }

  &.solid {
    background-color: var(--ss-colors-backgrounds-container);
  }
}

//INPUTS

input,
label {
  display: block;
}

/* Hide arrows in Chrome, Safari, Edge, and Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
}

.ss-form-group {
  margin-bottom: 24px;
  width: 100%;
  position: relative;

  label {
    font-size: 13px;
    font-weight: 400;
    color: var(--ss-colors-input-label);
    margin-bottom: 8px;
  }

  .field-error {
    font-size: 0.813rem;
    font-weight: 300;
    margin-top: 9px;
    color: #f3515f; // needs var
  }
}

.ss-input {
  height: 42px;
  padding: 0px 16px;
  background-color: var(--ss-input-bg);
  border-radius: 8px;
  font-weight: 400;
  font-family: var(--ss-font-roboto);
  color: var(--ss-text-primary);
  border: none;

  &.dark {
    background: var(--ss-colors-darks-rock);
    color: var(--ss-colors-off-white);
  }

  // &.bordered {
  //     border: 0.8px solid var(--ss-colors-input-border);
  // }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  &.expanded {
    width: 100%;
  }

  &::placeholder {
    // color: var(--ss-colors-input-placeholder);
    color: #929db9;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #4a5463; //needs var
    cursor: not-allowed;
  }

  &.t-area {
    padding: 16px;
  }

  &.error {
    border: 1px solid #f3515f; // needs var
  }
}

.ss-input-append-wrapper {
  display: flex;

  .ss-input {
    border-top-right-radius: 0em;
    border-bottom-right-radius: 0em;
  }

  .ss-input-append-container {
    background: #363945; // NEEDS VAR
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #81859e; // NEEDS VAR
  }
}

.base-error-text {
  font-size: 0.813rem;
  font-weight: 300;
  color: #f3515f; // needs var
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 20px;
  cursor: pointer;
  position: relative;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMjUgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+R3JvdXAgNTwvdGl0bGU+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtNSI+CiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIxOCI+PC9yZWN0PgogICAgICAgICAgICA8cGF0aCBkPSJNOC44MDQxMzMyOSw3LjIxODA0NjAzIEM5LjA3ODI1MzI5LDYuOTI2NzMzNzkgOS41MjIwNjAxLDYuOTI3NDA0NyA5Ljc5NTQwMzU0LDcuMjE5NTQ0NTUgQzEwLjA0MTQxMjYsNy40ODI0NzA0MiAxMC4wNjU1MDM2LDcuODkxODc3OTIgOS44NjgwMjgyNiw4LjE4MzQ5Njc5IEw5Ljc5Mzk5NzQ1LDguMjc1OTc4MDMgTDYuNDk0OTMyMDgsMTEuNzgxOTU0IEM2LjI0ODcxODg0LDEyLjA0MzYwOTIgNS44NjU0OTEzOCwxMi4wNjk3NzQ3IDUuNTkxODc1MjksMTEuODYwNDUwNSBMNS41MDUwNjc5MiwxMS43ODE5NTQgTDIuMjA2MDAyNTUsOC4yNzU5NzgwMyBDMS45MzE4ODI1NCw3Ljk4NDY2NTc4IDEuOTMxMjUzMDIsNy41MTE2ODQ0MSAyLjIwNDU5NjQ2LDcuMjE5NTQ0NTUgQzIuNDUwNjA1NTUsNi45NTY2MTg2OCAyLjgzNDY4OTk4LDYuOTI5NzgyNjYgMy4xMDg4ODAyNSw3LjEzOTQxMTM0IEwzLjE5NTg2NjcxLDcuMjE4MDQ2MDMgTDYsMTAuMTk4MzE4MiBMOC44MDQxMzMyOSw3LjIxODA0NjAzIFoiIGlkPSJWZWN0b3IiIGZpbGw9IiNFRUFGMTIiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--ss-text-primary) !important;
  background-color: var(--ss-input-bg) !important;
  transition: background-color 5000s ease-in-out 0s;
}

.ss-primary-btn {
  background: var(--ss-btn-primary);
  color: var(--ss-colors-background);
  min-height: 42px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-family: var(--ss-font-secondary);
  font-style: italic;

  &:disabled {
    background: #e9a300; // NEEDS VAR
  }

  &.danger {
    background: var(--ss-colors-red);
  }

  &.warning {
    background: var(--ss-colors-red);
  }

  &.black {
    background: var(--ss-colors-background);
  }

  &.short {
    width: 88px;
    margin-left: 24px;
    font-size: 16px;
  }

  &.shorter {
    width: 152px;
    margin-left: 24px;
    font-size: 16px;
  }

  svg {
    height: 18px;
    margin-right: 8px;
    transform: translateY(1px);
  }

  &.margin {
    margin-bottom: 24px;
  }

  &.btn-spinner {
    border: 3px solid #1d2235; // needs var
    border-top-color: #e9a300;
  }
}

.ss-primary-btn[disabled] {
  cursor: not-allowed;
  color: #283d4e; // needs var
}

.ss-import-button {
  display: flex;
  gap: 0.65rem;
  font-size: 0.813rem;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: 300;
  color: var(--ss-text-secondary);

  svg path {
    fill: var(--ss-brand-primary);
  }
}

.import-setting-link {
  // color: #e9a301;
  color: var(--ss-text-muted);
  font-size: 0.813rem;
  text-decoration: underline;
  cursor: pointer;
}

.ss-secondary-btn {
  background: transparent;
  color: var(--ss-text-primary);
  min-height: 48px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--ss-brand-primary);
  cursor: pointer;
  padding: 0px 16px;

  &.o-auth {
    justify-content: flex-start;
    color: var(--ss-text-primary);
    padding: 0px 24px;
    border-color: #222531;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 300;
  }

  svg {
    height: 18px;
    margin-right: 16px;
    transform: translateY(1px);
  }

  &.margin {
    margin-bottom: 24px;
  }
}

.ss-secondary-btn[disabled] {
  border-color: var(--ss-colors-darks-da-blues);
  color: var(--ss-colors-greys-ash);
}

.ss-utility-btn {
  background: var(--ss-btn-primary);
  outline: none;
  border: none;
  border-radius: 0.5em;
  min-width: 80px;
  height: 32px;
  color: white;
  cursor: pointer;
  color: #03050b;
  font-weight: 500;

  &:disabled {
    background: #af7a00;
    cursor: not-allowed;
  }

  &.secondary {
    background: transparent;
    border: 0.8px solid var(--ss-btn-primary);
    font-weight: 400 !important;
    color: var(--ss-text-soft-mute);
  }

  &.padded {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.fluid {
    width: 100%;
  }
}

//SWITCH

.ss-switch {
  margin: 0px;
}

.ss-switch input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.ss-switch input[type='checkbox']:checked + label {
  background: var(--ss-brand-primary);
}

.ss-switch input[type='checkbox']:checked + label::after {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}

.ss-switch label {
  cursor: pointer;
  width: 48px;
  height: 24px;
  background: var(--ss-colors-greys-cement);
  display: block;
  border-radius: 24px;
  position: relative;
}

.ss-switch label::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 16px;
  transition: 0.3s;
}

//LOADING

.ss-base-page-loading-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 12em;
}

.ss-loading-prompt-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.ss-page-loading-prompt {
  color: #75819c;
  font-weight: 300;
}

.ss-time-delay-text {
  margin-top: 0.5em;
  font-size: 0.75em;
  font-weight: 300;
  color: var(--ss-brand-primary);
  animation: timeText 1s ease 3s forwards;
  opacity: 0;
}

.ss-time-delay-text-short {
  margin-top: 0.5em;
  font-size: 0.75em;
  font-weight: 300;
  color: var(--ss-brand-primary);
  animation: timeText 1s ease 0s forwards;
  opacity: 0;
}

@keyframes timeText {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ss-spinner {
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 3px solid var(--ss-colors-greys-ash);
  border-radius: 50%;
  border-top-color: var(--ss-text-primary);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  &.btn-spinner {
    width: 26px;
    height: 26px;
    border: 3px solid #e9a300; // needs var
    border-top-color: #1d2235;
  }

  &.btn-spinner-sm {
    width: 18px;
    height: 18px;
    border: 2px solid #e9a300; // needs var
    border-top-color: #1d2235;
  }

  &.page-spinner {
    width: 32px;
    height: 32px;
    border: 3px solid #e9a300;
    border-top-color: #1a1925;
  }

  &.passive {
    width: 30px;
    height: 30px;
    border: 3px solid #25293c;
    border-radius: 50%;
    border-top-color: #e9a300;
  }

  &.input-row-loader {
    width: 24px;
    height: 24px;
    border: 4px solid #25293c;
    border-radius: 50%;
    border-top-color: #404a65;
    align-self: center;
  }

  &.import-card-spinner {
    width: 24px;
    height: 24px;
    border: 3px solid #25293c;
    border-radius: 50%;
    border-top-color: var(--ss-colors-blue);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

hr {
  border-color: #5b6797;
  border-bottom: none;
}

.ss-legal-page-wrapper {
  height: 100%;
  max-width: 1280px;
  margin: 32px auto;
  padding: 32px;
  color: var(--ss-colors-off-white);

  &.legal {
    h1 {
      font-size: 24px;
      margin: 16px 0px 16px 0px;
      color: var(--ss-colors-off-white);
    }

    h2 {
      font-size: 18px;
      font-weight: 600;
      margin: 16px 0px 8px 0px;
      color: var(--ss-colors-off-white);
    }

    p,
    li {
      color: var(--ss-colors-off-white);
    }
  }
}

.ss-terms-prompt-wrapper {
  color: #646e7e; //needs var
  margin-top: 24px;
  font-size: 10px;
  text-align: center;

  a {
    color: #646e7e;
    font-weight: 800;
  }
}

// PROGRESS ELEMENTS

.ss-progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 1em;
  overflow: hidden;

  .progress-segment {
    flex-grow: 1;
    display: flex;
    align-items: center;
    position: relative;
  }

  .segment-content {
    flex-grow: 1;
    text-align: center;
    padding: 6px;
    background-color: #3b4350; //2d3545
    color: #9098ac; // NEEDS VAR
    font-size: 0.813rem;
  }

  .active .segment-content {
    background-color: #e9a300; //NEEDS VAR
    color: #231f05;
    font-weight: 600;
    border-right: 2px solid #2e3545;
  }
}

// FILE UPLOADER STYLES

.ss-add-file-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.8px solid #2d3445;
  margin-bottom: 20px;
  padding: 0px 0px 20px 0px;
  gap: 1.5rem;
  align-items: center;

  .file-info-section {
    border: 0.8px solid #2d3445;
    border-radius: 8px;
    padding: 18px;
    text-align: center;
    cursor: pointer;
    position: relative;
    width: 100%;
    font-size: 0.813rem;
    font-weight: 300;
    color: var(--ss-text-soft-mute);
    background: #0e121c;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon {
      color: #09a4dd;
      margin-bottom: 8px;
    }
  }

  .file-drop-section {
    width: 100%;
  }
}

.ss-wizard-progress-error {
  color: #fbf8f8;
  border: 1px solid #ff5c5c; // NEEDS VAR
  border-radius: 9px;
  padding: 0.75em;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5em;
  background: #0f121c;
}

// PROGRESS LOG STYLES

.ss-job-progress-log-wrapper {
  background: #111217; // needs var
  min-height: 17rem;
  border-radius: 0.5rem;
  padding-top: 8px;
  padding-bottom: 48px;
  position: relative;
  border: 0.8px solid var(--border);

  &.fs {
    .progress-line-items {
      height: calc(100vh - 300px);
      overflow-y: scroll;
      position: relative;
      padding-bottom: 26px;
      padding-right: 90px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &.active {
    background: #11161d;
  }

  &.failed {
    background: #11161d;
  }

  .counter {
    top: 18px;
    right: 18px;
    position: absolute;
    font-size: 0.813rem;
    font-family: 'Source Code Pro', monospace;
    color: var(--ss-text-secondary);
  }

  .progress-line {
    display: flex;
    padding: 8px 16px;
    align-items: left;
    flex-direction: column-reverse;
    gap: 0.75em;

    .ss-spinner {
      height: 14px;
      width: 14px;
      margin-right: 8px;
      border: 2px solid #9effc6;
      border-top-color: #181e27;
    }

    .text {
      // font-family: 'Fira Code', monospace;
      color: #9effc6;
      font-size: 12px;
      font-weight: 300;
    }
  }
}

// SHARED JOB STYLES

.lineups-info-wrapper {
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 1px solid var(--border);

  .lineups-info-header {
    font-size: 13px;
    color: var(--ss-text-primary);
    font-weight: 400;
    margin-bottom: 6px;
  }

  .lineups-info-desc {
    font-size: 12px;
    color: var(--ss-text-secondary);
    font-weight: 400;
    line-height: 1.6;
  }
}

.late-swap-chip {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 4px 8px;
  margin-right: 1em;
  border-radius: 9px;

  .icon {
    color: #19d9c2;
    transform: translateY(2px);
  }

  .text {
    font-size: 12px;
    font-weight: 800;
    color: var(--ss-text-medium-mute);
  }
}

.ss-save-draftGroupWrapper {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;

  .prompt {
    display: flex;
    align-items: center;
    gap: 0.5em;

    .prompt-label {
      font-size: 0.813rem;
      color: var(--ss-text-soft-mute);
    }

    .existing-count {
      font-size: 0.813rem;
      color: var(--ss-text-muted);
    }
  }
}

.job-card-coming-soon {
  font-size: 0.75rem;
  padding-left: 1rem;
  padding-top: 2px;
  color: var(--ss-text-medium-mute);
}

.ss-job-list-status {
  display: flex;
  align-items: center;

  .status-chip {
    color: var(--ss-text-primary);
    cursor: pointer;
    background: #26293a;
    border-radius: 0.25rem;
    font-size: 0.813rem;
    padding: 6px 8px;
    min-width: 6.5em;
    min-height: 32px;
    text-align: center;
    font-family: var(--ss-font-secondary);
    font-weight: 500;
  }
}

.ss-job-list-timestamp {
  color: var(--ss-text-muted);
  font-size: 0.75rem;
  margin: 0.5em 0.5em 0em 1em;
}

// SHARED SUMMARY STYLES

.ss-report-chart-row-grid {
  display: grid;
  grid-template-columns: 80% 1fr;
  gap: 1em;
  margin-bottom: 1em;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.ss-report-card {
  border-radius: 14px;
  width: 100%;
  min-height: 24em;
  background: var(--ss-card-bg);
  box-shadow: 2px 4px 4px var(--ss-card-shadow);
  opacity: 0;

  &.minified {
    min-height: 1em;
  }

  &.pad {
    padding: 2em;
  }

  &.tall {
    min-height: 34em;
  }

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .report-card-header {
    padding: 1.25rem;
    padding-left: 0em;
    margin-left: 1.25rem;
    border-bottom: 0.8px solid var(--ss-card-border);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .report-card-title {
      font-weight: 600;
      font-size: 0.875rem;
      color: var(--ss-text-primary);
    }
  }

  .report-card-explanation {
    color: var(--ss-text-soft-mute);
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.6;
    margin-bottom: 1.5em;
    margin-top: 0.5em;
  }

  .report-card-body {
    padding: 1.25rem;
    padding-top: 0.5em;
    color: var(--ss-text-soft-mute);
  }

  @media screen and (max-width: 768px) {
    min-height: 12em;
  }
}

.download-button {
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.813rem;
  color: var(--ss-text-soft-mute);
}

.summary-block {
  display: block;
  margin-top: 1.5em;
  opacity: 0;

  .summary-title {
    color: var(--ss-text-soft-mute);
    font-weight: 600;
    font-size: 0.875rem;
  }

  .stat-row {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    margin-top: 0.5em;

    .stat {
      display: block;

      .label {
        color: #e9a300;
        font-size: 0.813rem;
        font-weight: 500;
        margin-bottom: 6px;
      }

      .value {
        font-size: 0.875rem;
        font-weight: 300;
        color: var(--ss-text-soft-mute);
      }
    }
  }
}

.insight-table {
  position: relative;
}

.btn-text {
  text-align: left;
}

.optimizer-available-prompt-wrapper {
  cursor: pointer;
  border: 0.8px solid #2d3445;
  display: flex;
  gap: 0.75em;
  background: #0e121c;
  margin-bottom: 12px;
  border-radius: 0.5em;
  align-items: center;
  padding: 6px 12px 6px 12px;

  .icon {
    color: var(--icon-highlight);
    transform: translateY(2px);
  }

  .message {
    font-size: 13px;
    font-weight: 400;
  }
}

.import-modal-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-bottom: 1em;

  a {
    font-size: 0.875rem;
  }
}

// CONFIG OPTIONS STYLES

.settings-wrapper {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  max-height: calc(100vh - 243px);
  // overflow-y: scroll; SCROLL EXPERIMENT

  &.stand-alone {
    display: block;
    position: relative;
  }

  &.solo {
    display: flex;
    justify-content: center;
    max-width: 768px;
    margin: 0 auto;
  }

  .rules-list {
    padding: 16px 8px 16px 16px;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 40px;
    max-height: inherit;
  }

  .corr-value-input {
    input {
      max-width: 100px;
    }
  }

  .save-row {
    display: flex;
    justify-content: flex-end;

    .actions {
      display: flex;
      gap: 1em;
    }
  }

  .centered-form {
    margin: 0 auto;
    padding: 16px;
    width: 100%;
    height: calc(100vh - 233px);
    overflow-y: scroll;

    .inline-inputs {
      .ss-form-group {
        .tool-tip-row label {
          font-size: 12px;
          font-weight: 400;
          color: var(--ss-colors-input-label);
          margin-bottom: 0;
        }

        .ss-input {
          width: 96px;
          height: 34px;
          font-size: 12px;
        }
      }

      .ss-form-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        border-bottom: 0.8px solid var(--border);
        padding-bottom: 12px;
        margin-bottom: 12px;

        .tool-tip-row label {
          font-size: 12px;
          font-weight: 400;
          color: var(--ss-colors-input-label);
          margin-bottom: 0;
        }

        .ss-input {
          width: 96px;
          height: 34px;
          font-size: 12px;

          &.has-percentage {
            width: 64px;
          }
        }

        .ss-input-append-wrapper .ss-input-append-container {
          width: 34px;
        }
      }
    }
  }

  .job-options-header {
    font-size: 1rem;
    font-weight: 500;
    color: var(--ss-text-soft-mute);
    display: flex;
    margin-bottom: 16px;
  }

  .job-options-section-header {
    font-size: 13px;
    font-weight: 500;
    color: var(--ss-text-primary);
    display: flex;
    margin-bottom: 16px;
  }

  .rule-wrapper {
    padding: 0.25em;
    padding-left: 0.875em;
    margin-bottom: 1.25em;
    border: 8px solid #222531;
    border-left: 6px solid var(--ss-colors-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5em;
    background: #222531;
    border-top-left-radius: 0em;
    border-bottom-left-radius: 0em;

    .info {
      display: block;

      .title {
        font-weight: 600;
        font-size: 0.875rem;
        margin-bottom: 6px;
      }

      .players-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;

        .player {
          color: var(--ss-text-soft-mute);
          font-size: 0.75rem;
          font-weight: 300;
        }
      }
    }

    .icon {
      cursor: pointer;
      width: 32px;
      display: flex;
      justify-content: center;
    }
  }

  .input-row {
    gap: 1em;
    display: flex;
  }

  .staged-players {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    margin-bottom: 1em;

    .player-chip {
      display: flex;
      gap: 0.75em;
      font-size: 0.813rem;
      background: #0084b4;
      align-items: center;
      padding: 7px 10px 8px 10px;
      border-radius: 8px;

      .title {
        color: green;
      }

      .icon {
        cursor: pointer;
        transform: translateY(2px);
      }
    }
  }

  .staged-chips {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    margin-bottom: 1em;

    .item-chip {
      display: flex;
      gap: 0.75em;
      font-size: 0.813rem;
      background: #212536;
      align-items: center;
      padding: 7px 10px 8px 10px;
      border-radius: 8px;

      .title {
        color: green;
      }

      .icon {
        cursor: pointer;
        transform: translateY(2px);
      }
    }
  }

  .correlation-inputs-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;

    .ss-form-group {
      margin-bottom: 0em;
      width: auto;

      .ss-input {
        width: 100%;
      }
    }
  }

  .player-correlation-item {
    display: flex;
    gap: 0.5em;
    width: 100%;
    align-items: center;

    .player-box {
      background: var(--ss-input-bg);
      font-weight: 300;
      border-radius: 0.5em;
      font-size: 0.875rem;
      flex: 1;
      height: 32px;
      padding: 0em 1em;
      display: flex;
      align-items: center;
    }

    .value {
      color: green;
    }

    .remove {
      display: block;
    }
  }

  .selected-player-wrapper {
    background: var(--ss-input-bg);
    padding: 1em;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5em;
    margin-bottom: 1em;
    font-size: 0.875rem;
    height: 32px;

    .position {
      font-weight: 600;
      opacity: 0.7;
    }
  }

  .toggle-wrapper {
    display: flex;
    padding: 4px 0px;
    border-radius: 8px;
    justify-content: space-between;
    width: 100%;

    .label {
      font-size: 12px;
      font-weight: 400;
      color: var(--ss-colors-input-label);
      margin-bottom: 0;
    }
  }

  .preset-input-row {
    display: flex;

    .mock-preset-input {
      background: #222531;
      cursor: pointer;
      width: 100%;
      height: 34px;
      display: flex;
      align-items: center;
      padding: 0px 16px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;

      .text {
        font-size: 0.75rem;
        color: var(--ss-text-primary);
      }

      .icon {
        color: #e9a301; // NEEDS VAR
        transform: translate(4px, -1px);
      }
    }
  }

  .save-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;

    .save-text {
      color: #e9a301;
      font-size: 0.75rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .save-preset-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5em;

    .ss-input {
      height: 32px;
      font-size: 0.75rem;
    }

    .save-preset-btn {
      border: 1px solid #e9a300;
      border-radius: 8px;
      height: 34px;
      display: flex;
      align-items: center;
      min-width: 63px;
      justify-content: center;
      cursor: pointer;
      font-size: 0.75rem;
    }

    .cancel-preset-btn {
      border: 1px solid #b0c8c5;
      border-radius: 8px;
      height: 34px;
      display: flex;
      align-items: center;
      min-width: 63px;
      justify-content: center;
      cursor: pointer;
      font-size: 0.75rem;
      color: #b0c8c5;
    }
  }

  ul {
    text-align: left;
    list-style-type: none;
    margin: 0;
    padding: 0px;
    background: #111217;
    transform: translateY(-2px);
    max-height: 260px;
    overflow-y: scroll;
    border: 1px solid #2b3037;
    border-radius: 8px;
  }

  li {
    padding: 10px 16px;
    border-bottom: 1px solid #222531;

    .preset-item-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 24px;

      .name {
        font-size: 0.75rem;
        color: var(--ss-text-primary);
        cursor: pointer;
        width: 100%;
        margin-right: 20px;
        font-weight: 300;
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 0.5em;

        .default-wrapper {
          display: flex;

          .ss-spinner.passive {
            width: 16px;
            height: 16px;
          }

          .default-text {
            color: var(--ss-text-secondary);
            min-width: 88px;
            text-align: center;
            cursor: pointer;
            font-size: 0.75rem;
            min-height: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &.active {
              color: #06c9b2;
              justify-content: flex-end;
              margin-right: 0.5rem;
              font-size: 0.875rem;
              min-width: 96px;
              cursor: default;
            }
          }

          .delete-chip {
            color: var(--ss-text-soft-mute);
            font-weight: 600;
            cursor: pointer;
            transform: translate(3px, 2px);
          }
        }

        .delete-wrapper {
          min-width: 16px;
          min-height: 24px;
          cursor: pointer;
          margin-left: 0.5em;
          display: flex;
          align-items: center;

          .icon {
            color: var(--ss-text-soft-mute);
            transform: translateY(2px);
          }

          .ss-spinner.passive {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .preset-definition {
    background: #1d1f27;
    cursor: default;
    font-size: 0.813rem;
    color: var(--ss-text-muted);

    .cap-me-bro {
      text-transform: capitalize;
    }
  }

  li.active {
    background-color: #202537;
  }

  .preset-applied {
    display: inline-block;
    padding-left: 8px;
    color: #04d4ff;
    font-weight: 500;
    animation: bounce 1s ease-in-out forwards;
    animation-delay: 3s;
    opacity: 1;
  }

  @keyframes bounce {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

// OVERRIDES FOR NEW STYLE CONFIG

.settings-wrapper {
  .pill-input-wrapper {
    min-height: 32px !important;
    .pills-wrap {
      padding-left: 6px !important;
      .pill {
        font-size: 12px !important;
        padding: 2px 6px !important;
        align-items: center;
        background: var(--ss-data-label) !important;
        svg {
          transform: translateY(1px) !important;
        }
      }
    }
  }
}

.rules-list {
  display: block;

  .job-options-header {
    font-size: 14px;
    font-weight: 400;
    color: var(--ss-text-primary);
  }
  .import-setting-link {
    color: var(--ss-text-secondary);
    font-size: 12px;
  }
  .ss-input {
    height: 32px;
    font-size: 12px;
  }
  .staged-chips .item-chip {
    font-size: 12px;
  }
  .rule-wrapper {
    padding: 0.5rem 0.25rem 0.5rem 0rem;
    padding-left: 0.75em;
    margin-bottom: 1.25em;
    border: none;
    border-left: 2px solid var(--ss-brand-primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5em;
    background: #222531;
    border-top-left-radius: 0em;
    border-bottom-left-radius: 0em;
  }
  .staged-players .player-chip {
    display: flex;
    gap: 0.75em;
    font-size: 12px;
    background: var(--ss-data-label);
    align-items: center;
    padding: 7px 10px 8px 10px;
    border-radius: 8px;
    font-weight: 300;
  }
}

ul.absolute-list {
  position: absolute;
  z-index: 300;
  left: 0;
  right: 0;
}

// JOB DROP FORM STYLES

.ss-drop-projections-help-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5em;

  .help-prompt {
    cursor: pointer;
    display: flex;
    color: var(--ss-text-soft-mute);
    gap: 0.5em;
    font-size: 0.875rem;
    font-weight: 300;
  }
}

.template-links-prompt {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--ss-text-primary);
}

.template-links {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .template-link {
    color: var(--ss-text-muted);
    font-size: 0.813rem;
    cursor: pointer;
    text-decoration: underline;
  }
}

.ss-drop-projections-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 16em;

  .content {
    max-width: 28em;
    width: 100%;
    padding-top: 0em;

    .title {
      color: var(--ss-text-primary);
      font-weight: 700;
      font-size: 1.35rem;
      margin-bottom: 0.35em;
    }

    .sub-title {
      color: var(--ss-text-medium-mute);
      font-size: 0.813rem;
      line-height: 1.6;
    }

    .arrow-wrapper {
      display: flex;
      justify-content: center;
      color: #e9a300;
      margin-top: 12px;
      margin-bottom: 10px;
    }

    .file-info-section {
      border: 0.8px solid #2d3445;
      border-radius: 8px;
      padding: 18px;
      text-align: center;
      cursor: pointer;
      position: relative;
      width: 100%;
      font-size: 0.813rem;
      font-weight: 300;
      color: var(--ss-text-soft-mute);
      background: #0e121c;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .icon {
        color: #09a4dd;
        margin-bottom: 8px;
      }
    }

    .file-drop-section {
      width: 100%;
    }
  }
}

.file-drop-callout {
  border: 1px solid var(--ss-colors-blue);
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 10px 16px 10px 16px;
  border-radius: 8px;
  margin-bottom: 20px;

  .callout-icon {
    color: var(--ss-colors-blue);
  }

  .callout-info {
    font-size: 12px;
    line-height: 1.7;
    font-weight: 300;

    .callout-title {
      font-weight: 500;
      font-size: 13px;
    }

    .callout-msg {
      margin-bottom: 2px;
    }

    .callout-clear {
      cursor: pointer;
      text-decoration: underline;
      width: max-content;
    }
  }
}

// GLOBAL FILTER STYLES

.existing-filters-widget {
  border: 1px solid var(--border);
  border-radius: 8px;
  margin-bottom: 12px;

  .existing-header-row {
    display: flex;
    justify-content: space-between;
    padding: 6px 8px;
    align-items: center;
    cursor: pointer;

    .existing-header-text {
      font-size: 12px;
      color: var(--ss-text-primary);
      font-weight: 400;
    }

    .toggle-icon {
      background: #202332;
      border-radius: 64px;
      cursor: pointer;
      min-width: 28px;
      min-height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--ss-text-primary);
      
      svg {
        transform: translateY(-2px);
      }
    }
  }

  .existing-filter {
    display: flex;
    justify-content: space-between;
    padding: 8px 8px;
    border-top: 1px solid var(--border);
    align-items: center;

    .filter-info {
      font-size: 12px;
      color: var(--ss-text-secondary);
      line-height: 1.6;
    }

    .existing-filter-action {
      background: #202332;
      border-radius: 64px;
      cursor: pointer;
      min-width: 28px;
      min-height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--ss-text-primary);
      
      svg {
        transform: translateY(0px);
      }
    }
  }
}

// SMS 

.sms-code-input-row {
  display: flex;
  gap: 8px;

  .code-input {
    width: 100% !important;
    color: var(--ss-text-primary);
    background: var(--ss-input-bg);
    margin: 0;
    border: 1px solid var(--border) !important;
    outline: none;
  }
}

// TEMP TABLE OVERRIDES

.gui-cell:first-child {
  font-weight: 600;
}

//HELPERS

.cursor-p {
  cursor: pointer;
}

.w100p {
  width: 100%;
}

// MARGIN BOTTOM
.mb0 {
  margin-bottom: 0px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.mb32 {
  margin-bottom: 32px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb48 {
  margin-bottom: 48px;
}

.mb56 {
  margin-bottom: 56px;
}

// MARGIN LEFT
.ml8 {
  margin-left: 8px;
}

.ml16 {
  margin-left: 16px;
}

// MARGIN RIGHT
.mr8 {
  margin-right: 8px;
}

.mr16 {
  margin-right: 16px;
}

// MARGIN TOP
.mt0 {
  margin-top: 0px;
}

.mt8 {
  margin-top: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px !important;
}

.mt32 {
  margin-top: 32px;
}

// Padding-Bottom

.pb16 {
  padding-bottom: 16px;
}

.pb24 {
  padding-bottom: 24px;
}

.pb32 {
  padding-bottom: 32px;
}

.p16 {
  padding: 16px;
}

.p24 {
  padding: 24px;
}

.p32 {
  padding: 32px;
}

.mh156 {
  min-height: 156px !important;
}

.mh256 {
  min-height: 256px !important;
}

.w100p {
  width: 100%;
}

// FLEX HELPERS

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

// TABLE OVERRIDES

.table-team-chip {
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 10px;
  color: #fafafa;
  font-family: var(--ss-font-secondary);
  font-weight: 400;
}

.ss-table-count {
  padding-left: 0.25em;
  font-size: 0.875rem;
  line-height: 1;
  color: var(--ss-text-secondary);
}

.ss-table-download-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 400;
  gap: 0.5em;
  font-size: 0.813rem;
  line-height: 1;
  color: var(--ss-text-secondary);

  svg path {
    fill: #e9a301;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.ss-edit-cell-icon {
  color: #7785a1;
  padding-left: 4px;
}

.flex-cell {
  display: flex;
  align-items: center;
}

.table-input-chip {
  display: flex;
  background: #343b4d;
  justify-content: center;
  border-radius: 4px;
  padding: 2px 0px;
  cursor: text;
  min-height: 19px;
}

.ss-table-input {
  background: #343b4d;
  border-radius: 4px;
  text-align: center;
  border: none;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 0.875rem;
  color: white;
  width: -webkit-fill-available;
  position: absolute;
  left: 8px;
  top: 12px;
  min-height: 19px;
  right: 8px;

  &:hover {
    background: #3d465b;
  }

  &:focus-visible {
    border: none;
    outline: none;
  }
}

.ss-table-input::-webkit-inner-spin-button,
.ss-table-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

:host ::ng-deep .datatable-body-cell.content-editable[contenteditable='true'] {
  background-color: #eef0f1;
}

.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  display: flex;
  align-items: center;
  vertical-align: none;
}

.ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: none;
  display: flex;
  align-items: center;
  height: 13px;
}

.ngx-datatable.material {
  box-shadow: none;
  background: #151922;
  overflow: hidden;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-top: 1em;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  background: #2d3445;
  color: var(--ss-text-soft-mute);
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  background-color: #22283b;
  transition-property: background;
}

.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px #1b2635;
}

.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  color: var(--text-medium-mute);
  font-weight: 300;
}

.ngx-datatable.material .datatable-header {
  margin-bottom: 6px;
  overflow: visible;
}

// Latest univeral styles

.config-tab-control {
  background: #1e212d;
  min-height: 44px;
  display: flex;

  .tab-links {
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 0px 14px;

    .link {
      font-weight: 500;
      font-size: 12px;
      color: var(--ss-text-secondary);
      height: 44px;
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;

      &.active {
        color: var(--ss-brand-primary);
      }
    }
  }
}

.icon-utility-btn {
  background: #26293a;
  border-radius: 4px;
  cursor: pointer;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ss-text-primary);

  &:hover {
    opacity: 0.9;
  }

  svg {
    transform: translateY(2px);
  }
}

.table-team-row {
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: space-between;
  max-width: 136px;

  .home,
  .away {
    height: 24px;
    width: 24px;
    background: #a9b3ce;
    padding: 2px;
    border-radius: 2px;
    flex-shrink: 0;
  }

  .middle-data {
    text-align: center;
    margin: 0 6px;
    .at-symbol {
      color: #8f9fca;
      font-size: 12px;
      margin-bottom: 1px;
      transform: translateY(-1px);
    }

    .time {
      font-size: 11px;
      color: var(--ss-text-secondary);
      font-weight: 800;
    }
  }
}

.results-sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: var(--border);
  border-bottom: 1px solid var(--border);
  height: 32px;
  width: 100%;
  background: #1e212d; // needs var

  .left-wrap {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    gap: 1rem;

    .data-links {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0px 0px;

      .link {
        font-weight: 400;
        font-size: 12px;
        color: var(--ss-text-secondary);
        height: 44px;
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 0px 2px;

        &.active {
          color: #f4ae0a;
          font-weight: 500;
          padding: 0px 1px;
        }
      }
    }
  }

  .right-wrap {
    display: flex;
    justify-content: flex-end;
    padding: 0px 16px 0px 0px;
    gap: 0.875rem;

    .download-all-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 10px;

      .icon {
        transform: translateY(2px);
        color: var(--icon-highlight);
      }

      .copy {
        color: var(--ss-text-primary);
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
}

.non-selectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}
