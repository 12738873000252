:root {

    --ss-brand-primary: var(--ss-colors-yellow-300);

    --ss-colors-orange-darkest: #4c1600;
    --ss-colors-orange-darker: #8a3718;
    --ss-colors-orange: #EB5216;

    --ss-colors-green-darkest: #013C30;
    --ss-colors-green-dark: #027961;
    --ss-colors-green-darker: #03B591;
    --ss-colors-green: #03CEA4;


    --ss-colors-blue: #279AF1;

    --ss-colors-red-darkest: #3D0000;
    --ss-colors-red-darker: #660000;
    --ss-colors-red: #FF4747;
    --ss-colors-red-lightest: #FFADAD;

    --ss-colors-red-error: #CE2055;

    --ss-colors-yellow: #e7a836;
    --ss-colors-yellow-super-light: #EADFCE; 



    --ss-colors-greys-ash: #E5E5E5;
    --ss-colors-greys-cement: #8e99af;

    --ss-colors-background: #14213D;

    --ss-colors-background-darker: #070f22;
    --ss-colors-backgrounds-container: #12161C;
    
    --ss-colors-darks-slate: #0f1a35; // #1C232B
    --ss-colors-darks-da-blues: #2b3c52;
    --ss-colors-darks-cave: #0B0F12;
    --ss-colors-darks-rock: #16264d;

    --ss-colors-tint-white: #F9F2EF;

    //STRICT COLORS

    --ss-colors-input-label: var(--ss-text-primary);
    --ss-colors-input-border: #3F3632;
    --ss-colors-input-placeholder: #9195a5; // needs final color
    --ss-colors-input-background: #f4f6f9;

    --ss-colors-borders-primary: #2b3245;

    --ss-colors-empty-state-text: #9f9888;

    //FONTS

    --ss-font-primary: 'Roboto', sans-serif;
    // --ss-font-secondary: 'Lexend', sans-serif;
    --ss-font-secondary: 'exo 2', sans-serif;
    --ss-font-roboto: 'Roboto', sans-serif;


    // NEW VARIABLES VET THE ABOVE AFTER ALL NEW ARE IN PLACE

    --ss-primary-bg: #F3F4F7;
    --ss-primary-nav-bg: #09080b;
    --ss-sub-nav-link: #F7FBFC;
    --ss-sub-nav-link-active: var(--ss-colors-yellow-300);

    --ss-menu-bg: #1a1c21;
    --ss-menu-card-bg: #32363B;

    --ss-text-primary: #D3DCF1; //fafafa
    --ss-text-secondary: #929db9;  //9fa5b3
    --ss-text-soft-mute: #d9d9d9;
    --ss-text-medium-mute: #bfbfbf;
    --ss-text-muted: #808592;

    --ss-border-primary: #444d60;

    --ss-btn-primary: #e9a300; //4f46e3

    --ss-card-bg: #0a0d15;
    --ss-base-card-bg: #111218;
    --ss-card-border: #192235;
    --ss-card-shadow: rgb(19 19 19 / 20%);
    --ss-card-header-bg: #343336;
    --ss-input-bg: #212531;

    --ss-panel-bg: #0e121c;
    --ss-panel-border: #2d3445;

    // VETTED COLORS
    --ss-colors-off-white: #f7fbfc;

    --ss-colors-yellow-200: #e9a300;
    --ss-colors-yellow-300: #e9a300;

    --ss-colors-green-200: #00a07f;


    --ss-dk-green: #61B510;
    --ss-dk-orange: #F7760D;
    --ss-fd-blue: #1493FF;

    //UTILITY
    --ss-divider: #e7e7e7;
    --ss-data-label: #5c6577;

    // MODAL

    --ss-modal-bg: #111219;


    // new v2

    --background: #09080b;

    --border: #2b3037;

    --icon-highlight: #d7b61d; 
}